import { shopsRoutes } from "@/modules/Shops/Routes/shopsRoutes.js";
import { routesByName } from "@/modules/Shared/Helpers/routesHelper.js";

const routes = routesByName(shopsRoutes);

export default [
  {
    title: "Shop Performance & Analysis",
    subtitle: "shops.performance.subtitle",
    items: [
      {
        icon: "orders",
        route: routes["Orders"]
      },
      {
        icon: "tickets",
        route: routes["OrderDetails"]
      },
      {
        icon: "payments",
        route: routes["Payments"]
      },
      {
        icon: "sales",
        route: routes["Sales"]
      },
      {
        icon: "customers",
        route: routes["Customers"]
      }
    ]
  },

  {
    title: "Marketing tools",
    subtitle: "shops.marketing.subtitle",
    items: [
      {
        icon: "promotions",
        route: routes["Promotions"]
      },
      {
        icon: "variable-pricing",
        route: routes["VariablePricing"]
      },
      {
        icon: "periods",
        route: routes["MarketingPeriods"]
      }
    ]
  },

  {
    title: "shops.catalog.title",
    subtitle: "shops.catalog.subtitle",
    items: [
      {
        icon: "catalogs",
        route: routes["Catalogs"]
      },
      {
        icon: "products",
        route: routes["Products"]
      },
      {
        icon: "prices",
        route: routes["Prices"]
      },
      {
        icon: "resorts",
        route: routes["Resort"]
      },
      {
        icon: "email",
        route: routes["EmailConfiguration"]
      },
      {
        icon: "pages",
        route: routes["Pages"]
      },
      {
        icon: "participants",
        route: routes["Participants"]
      },
      {
        icon: "periods",
        route: routes["Periods"]
      },
      {
        icon: "acces-point",
        route: routes["AccessPoint"]
      },
      {
        icon: "taxes",
        route: routes["Taxes"]
      },
      {
        icon: "payment-gateway",
        route: routes["PaymentGateways"]
      },
      {
        icon: "capacity",
        route: routes["Capacity"]
      },
      {
        icon: "snippets",
        route: routes["Snippets"]
      },
      {
        icon: "hooks",
        route: routes["Hooks"]
      }
    ]
  }

  // {
  //   title: "shops.campaigns.title",
  //   subtitle: "shops.campaigns.subtitle",
  //   items: [
  //     {
  //       icon: "manage-campaigns",
  //       title: "Manage campaigns",
  //       url: "/shop"
  //     }
  //   ]
  // },
  // {
  //   title: "shops.settings.title",
  //   subtitle: "shops.settings.subtitle",
  //   items: [
  //     {
  //       icon: "terms-conditions",
  //       title: "Terms and conditions",
  //       url: "/shop"
  //     }
  //   ]
  // }
];
