var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.resortIsEmpty
      ? _c("div", [
          _vm._v(
            "Unable to select resort. Please, reload the page or contact us."
          )
        ])
      : _c(
          "div",
          [
            _c("statistics-slider", {
              attrs: {
                "resource-type": "shop",
                "resource-uuid": _vm.resourceUuid
              }
            }),
            _c("menu-card", {
              staticClass: "mb-base",
              attrs: {
                identifier: _vm.$route.params.uuid,
                pannels: _vm.pannels
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }