<template>
  <div>
    <div v-if="resortIsEmpty">Unable to select resort. Please, reload the page or contact us.</div>
    <div v-else>
      <statistics-slider resource-type="shop" :resource-uuid="resourceUuid"/>
      <menu-card class="mb-base" :identifier="$route.params.uuid" :pannels="pannels" />
    </div>
  </div>
</template>

<script>

import MenuCard from "@/modules/Shared/Components/menu-card/MenuCard.vue";
import StatisticsSlider from "@/modules/Statistics/Components/StatisticsSlider";
import shopMenuItems from "./shopMenuItems.js";
import { generateMenu } from "@/modules/Shared/Helpers/menuHelper.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "shop-menu",

  components: {
    StatisticsSlider,
    MenuCard,
  },

  data() {
    return {}
  },

  async created() {
    await this.activeResort(parseInt(this.$route.params.uuid));
  },

  computed: {
    ...mapGetters("shops/resorts", {
      resort: "resort",
    }),
    pannels() {
      return generateMenu(shopMenuItems, this.$route.params.uuid);
    },
    resourceUuid() { return this.$route.params.uuid },
    resortIsEmpty() {
      return Object.keys(this.resort).length === 0
    }
  },

  methods: {
    ...mapActions("shops/resorts", {
      setActiveResort: "setActiveResort"
    }),
    async activeResort(newValue) {
      this.$vs.loading();
      try {
        await this.setActiveResort(parseInt(newValue));
      } catch (e) {
        console.warn(e);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    }

  },

  watch: {
    "$route.params.uuid"(newValue) {
      this.activeResort(newValue);
    },
  }
};
</script>
